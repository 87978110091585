import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import callApi from './helpers/callapi';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { getTheme, viewportWidth } from 'helpers/helpers';
import { Route, Switch, Redirect, useLocation, useHistory, Link } from 'react-router-dom';

import cn from 'classnames';
// import UserList from 'containers/User/List';

import Contact from 'containers/Contact';
import Tutorial from 'containers/Tutorial';

import CenterPage from 'containers/Center/Main';
import SitePage from 'containers/Site/Main';
import CenterMangementPage from 'containers/Center/Management';
import SiteRoomList from 'containers/Site/RoomList';

import ExamApplication from 'containers/Exam/List';
import ExamDetail from 'containers/Exam/Exam-Detail';

import SiteSelect from 'containers/Day/SiteSelect';
import RoomSelect from 'containers/Day/RoomSelect';

// import SchoolList from 'containers/School/List';
// import SchoolDownloadEnrollment from 'containers/School/DownloadEnrollment';

// DEMO
import Demo from 'containers/Demo/demo';

// Containers

// import 'react-big-calendar/lib/css/react-big-calendar.css';

// Components
import { Spin, Modal } from 'antd';
import { UserOutlined, SettingOutlined, BarsOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { useIdleTimer } from 'react-idle-timer';

// menus
import menus from './configs/menus';

moment.locale('th');
const MOBILE_MENU_WIDTH = 240;

const notRedirectToLoginPaths = ['/reset-password', '/credential', '/forgot-password'];

export default function () {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isClosed] = useState(false);

  const [smallNav, setNav] = useState(false);

  const [isLogoutModalShow, setIsLogoutModalShow] = useState(false);
  const adminConfig = useSelector((state) => state.configs || {});

  const handleOnIdle = (event) => {
    if (location.pathname === '/credential') return null;
    if (location.pathname === '/reset-password') return null;

    setIsLogoutModalShow(true);

    if (!isLogoutModalShow) {
      const accessToken = Cookies.get('accessToken');
      Cookies.remove('accessToken');
      Modal.warning({
        title: '',
        content: (
          <div>
            ท่านไม่ได้ดำเนินการใด ๆ ในระบบเป็นเวลา 10 นาที ท่านจะถูก log off โดยอัตโนมัติ ถ้าท่านต้องการใช้งาน กรุณา
            login อีกครั้ง
          </div>
        ),
        onOk() {
          Cookies.set('accessToken', accessToken);
          setIsLogoutModalShow(false);
          _onLogout();
        },
        cancelButtonProps: <span></span>,
      });
    }
  };

  const handleOnActive = (event) => {
    if (location.pathname === '/credential') return null;
    if (location.pathname === '/reset-password') return null;

    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      Cookies.set('accessToken', accessToken, {
        // expires: 1 / (24 * 6),
      });
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    onAction: handleOnActive,
    debounce: 500,
  });

  const handleNav = () => {
    setNav(!smallNav);
  };

  const [currentAdmin, setCurrentAdmin] = useState();
  const [ticketCount, setCountTicket] = useState(0);

  useEffect(() => {
    // const getCountTicket = async () => {
    //   const { count } = await callApi({ url: '/tickets/count' });
    //   setCountTicket(count);
    // };
    const getAdminConfig = async () => {
      const configs = await callApi({ url: '/configs/admin' });
      dispatch({ type: 'UPDATE_CONFIGS', data: configs });
    };
    const verify = async () => {
      try {
        const me = await callApi({ url: '/users/me' });

        if (me.role !== 'center' && me.role !== 'center_staff') {
          alert(me.role);
          Cookies.remove('accessToken');
          setTimeout(() => {
            window.location.href = 'https://tc65internal.com/credential';
          }, 1);
          return;
        }
        dispatch({ type: 'UPDATE_USER', data: me });

        setCurrentAdmin(me);
        await getAdminConfig();

        // if (me.role !== 'staff') {
        //   getCountTicket();
        // }
      } catch (error) {
        dispatch({ type: 'UPDATE_USER', data: null });
        if (notRedirectToLoginPaths.indexOf(location.pathname) === -1) {
          // console.log('check');
          window.location.href = 'https://mytcas.com';
        }
      }
    };
    verify();
  }, []);

  const [loggingOut, setLoggingOut] = useState(false);
  const _onLogout = async () => {
    if (loggingOut) return;
    try {
      setLoading(true);
      setLoggingOut(true);
      await callApi({
        method: 'POST',
        url: '/users/logout',
      });

      Cookies.remove('accessToken');
      setTimeout(() => {
        window.location.href = '/credential';
      }, 1);

      setLoggingOut(false);
    } catch (error) {
      console.error('Error while trying to log out.', error);
      window.location.href = '/credential';
      setLoggingOut(false);
    }
  };

  if (location.pathname === '/credential') return null;
  if (location.pathname === '/reset-password') return null;
  if (location.pathname === '/coming-soon') return null;
  if (!currentAdmin) return null;

  if (!Cookies.get('accessToken')) {
    // window.location.href = 'https://mytcas.com';
    return null;
  }

  const checkMenuVisibleFromConfig = (configVisibleField) => {
    if (configVisibleField) {
      if (configVisibleField.startsWith('!')) {
        return !adminConfig[_.trim(configVisibleField, '!')];
      }
      return adminConfig[configVisibleField];
    }
    return true;
  };

  // close system

  if (isClosed && currentAdmin.email !== 'golf@seedwebs.com') {
    // Cookies.remove('accessToken');
    // alert('ระบบปิดการใช้งานระหว่างที่มีการสมัครสอบ');

    setTimeout(() => {
      window.location.href = '/coming-soon';
    }, 1);
  }

  return (
    <section className={smallNav ? 'small-nav' : null}>
      <Spin spinning={loading}>
        <div>
          {location.pathname !== '/credential' && (
            <div className="t-head">
              <h3 className="university">
                {currentAdmin.role === 'staff' && currentAdmin.university_name && (
                  <span>{currentAdmin.university_name}</span>
                )}
                {(currentAdmin.role === 'center' || currentAdmin.role === 'center_staff') &&
                  currentAdmin.center &&
                  currentAdmin.center.center_name && <span>{currentAdmin.center.center_name}</span>}
              </h3>
              <ul className="t-user">
                <li className="info">
                  <a>
                    {currentAdmin && (currentAdmin.name || currentAdmin.email)}
                    <b>{currentAdmin && currentAdmin.role === 'super' && '• ผู้ดูแลระบบ'}</b>
                  </a>
                </li>
                <li>
                  <a className="btn-gray" style={{ cursor: 'pointer' }} onClick={_onLogout}>
                    ออกจากระบบ
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div className="t-body">
            <div className="t-side">
              <ul className="t-nav">
                <li className="m-home">
                  <a href="/center">
                    <img src="/img/logo.svg" alt="Logo" width="118" height="44" />
                    <b className="logo-text">ศูนย์สอบ</b>
                  </a>
                </li>
                {currentAdmin &&
                  menus.map((menu) => {
                    let { key, to, title, roles = [], submenus = [] } = menu;
                    const currentRole = currentAdmin.role;
                    const isActive =
                      location.pathname === to ||
                      submenus.reduce((result, submenu) => {
                        result = result || location.pathname === submenu.to;
                        return result;
                      }, false);

                    if (roles.length === 0 || roles.indexOf(currentRole) > -1) {
                      return (
                        <li className={cn({ active: isActive }, key)} key={key}>
                          {
                            <Link to={to}>
                              <i className={`i-${key}`}></i>
                              <span>{title}</span>
                              {key === 'tickets' && ticketCount > 0 && <b className="noti">{ticketCount}</b>}
                            </Link>
                          }

                          {Boolean(submenus.length) && isActive && (
                            <ul className="sub">
                              {submenus.map(({ to, title, key, roles = [], configVisibleField }) => {
                                if (
                                  (roles.length === 0 || roles.indexOf(currentRole) > -1) &&
                                  checkMenuVisibleFromConfig(configVisibleField)
                                ) {
                                  return (
                                    <li
                                      className={cn(
                                        {
                                          active: location.pathname === to,
                                        },
                                        key
                                      )}
                                      key={key}
                                    >
                                      <Link to={to}>
                                        <i className={`i-${key}`}></i>
                                        <span>{title}</span>
                                      </Link>
                                    </li>
                                  );
                                }
                                return null;
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    }
                    return <div key={key}></div>;
                  })}

                {/* ตัวอย่างเมนูย่อย 
                <li className="active">
                  <a href="/demo">ตัวอย่างเมนูและการแสดงผล</a>
                  <ul className="sub">
                    <li>
                      <a href="#1">เมนูย่อยรายการที่ 1</a>
                    </li>
                    <li className="active">
                      <a href="#2">เมนูย่อยรายการที่ 2</a>
                    </li>
                    <li>
                      <a href="#3">เมนูย่อยรายการที่ 3</a>
                    </li>
                    <li>
                      <a href="#4">เมนูย่อยรายการที่ 4</a>
                    </li>
                  </ul>
                </li>
                */}

                <li className="m-collapse">
                  <a onClick={handleNav}>
                    <i className="i-collapse"></i>
                    <span>ย่อเมนู</span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="t-content">
              <Switch>
                <Route exact path="/demo" component={Demo} />

                <Route exact path="/center" component={CenterPage} />
                <Route exact path="/center/management" component={CenterMangementPage} />
                <Route exact path="/site" component={SitePage} />
                <Route exact path="/site/:siteId/room" component={SiteRoomList} />

                <Route exact path="/day/:day/site" component={SiteSelect} />
                <Route exact path="/day/:day/site/:siteId/room" component={RoomSelect} />

                <Route exact path="/applications" component={ExamApplication} />
                <Route exact path="/applications/:citizenId" component={ExamDetail} />

                <Route exact path="/tutorial" component={Tutorial} />
                <Route exact path="/contact" component={Contact} />

                {/* <Redirect to="/credential" /> */}
              </Switch>
            </div>
          </div>
        </div>
      </Spin>
    </section>
  );
}
