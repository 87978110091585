import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { TGATTPATINFOS, ALEVELINFOS } from 'constants/exam-days';

import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// constants
import ROUNDS from 'constants/rounds';
import TCAS_STATUSES from 'constants/tcas_statuses';

// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const adminConfig = useSelector((state) => state.configs || {});

  const [currentRound, setCurrentRound] = useState(adminConfig.current_round);

  const [center, setCenter] = useState(null);

  const disabledTGATTPAT = adminConfig.center_active_test !== 'TGATTPAT';
  const disabledAlevel = adminConfig.center_active_test !== 'ALEVEL';

  const updateConfig = async () => {
    const configs = await callApi({ url: '/configs/admin' });
    dispatch({ type: 'UPDATE_CONFIGS', data: configs });
  };
  const getCenter = async () => {
    const center = await callApi({ url: '/exam-centers/me' });
    setCenter(center);
  };
  useEffect(() => {
    getCenter();
  }, []);

  // {['1', '2', '4'].map((roundNumber) => {
  //   const round = find(ROUNDS, { value: `${roundNumber}_2565` });

  //   if (adminConfig.current_round !== roundNumber) {
  //     return <></>;
  //   }

  // }
  if (!center) {
    return <div></div>;
  }

  const getSites = (dayStat) => {
    if (me.exam_type === 'cbt') return get(dayStat, `cbt_sites`) || 0;
    if (me.exam_type === 'cbt_pbt') return get(dayStat, `cbt_sites`) + get(dayStat, `pbt_sites`) || 0;
    return get(dayStat, `pbt_sites`) || 0;
  };
  const getRooms = (dayStat) => {
    if (me.exam_type === 'cbt') return get(dayStat, `cbt_rooms`) || 0;
    if (me.exam_type === 'cbt_pbt') {
      return get(dayStat, `cbt_rooms`) + get(dayStat, `rooms`) + get(dayStat, `special_rooms`) || 0;
    }
    return get(dayStat, `rooms`) + get(dayStat, `special_rooms`);
  };
  const getSeats = (dayStat) => {
    if (me.exam_type === 'cbt') return get(dayStat, `cbt_seats`) || 0;
    if (me.exam_type === 'cbt_pbt') {
      return get(dayStat, `cbt_seats`) + get(dayStat, `seats`) + get(dayStat, `special_seats`) || 0;
    }
    return get(dayStat, `seats`) + get(dayStat, `special_seats`);
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div>
          <div className="t-main">
            <h1>จัดการสนามสอบ</h1>

            {me.center && (
              <p>
                การจัดสอบ : การสอบ TGAT/TPAT และ A-Level <br />
                รหัสศูนย์สอบ : {me.center.center_code} <br />
                ศูนย์สอบ : {me.center.center_name}
              </p>
            )}

            <h2 className="color-blue">ตารางสอบ</h2>
            <h3>TGAT/TPAT</h3>
            <div
              className="grid gap-16"
              style={disabledTGATTPAT ? { opacity: 0.6, cursor: 'not-allowed' } : {}}
              // style={{ opacity: 0.6 }}
            >
              {TGATTPATINFOS.map((INFO) => {
                const stat = get(center, `info_by_days.day${INFO.dayIndex}`);
                return (
                  <div className="round">
                    <div className="round-date">
                      <span className="number">{INFO.index}</span> <span className="date">{INFO.date}</span>
                    </div>
                    <div className="round-time">
                      {INFO.examSlots.map((slot) => {
                        return (
                          <div className="item">
                            {slot.time} <span className="color-blue">{slot.subject}</span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="round-body">
                      <div className="col text-center">
                        <span>จำนวนสนามสอบ</span> <br />
                        <b>{getSites(stat)}</b>
                      </div>
                      <div className="col text-center">
                        <span>จำนวนห้องสอบ</span> <br />
                        <b>{getRooms(stat)}</b>
                      </div>
                      <div className="col text-center">
                        <span>จำนวนที่นั่ง</span> <br />
                        <b>{getSeats(stat)}</b>
                      </div>
                      <div className="col">
                        <Link to={`/day/${INFO.dayIndex}/site`} className="btn-ghost">
                          {disabledTGATTPAT ? 'ดูข้อมูลสนามสอบ' : 'จัดการสนามสอบ'}
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="_space"></div>
            <h3 className="color-blue">A-Level</h3>
            <div className="grid gap-16" style={disabledAlevel ? { opacity: 0.6, cursor: 'not-allowed' } : {}}>
              {ALEVELINFOS.map((INFO) => {
                const stat = get(center, `info_by_days.day${INFO.dayIndex}`);
                return (
                  <div className="round">
                    <div className="round-date">
                      <span className="number">{INFO.index}</span> <span className="date">{INFO.date}</span>
                    </div>
                    <div className="round-time">
                      {INFO.examSlots.map((slot) => {
                        return (
                          <div className="item">
                            {slot.time} <span className="color-blue">{slot.subject}</span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="round-body">
                      <div className="col text-center">
                        <span>จำนวนสนามสอบ</span> <br />
                        <b>{getSites(stat)}</b>
                      </div>
                      <div className="col text-center">
                        <span>จำนวนห้องสอบ</span> <br />
                        <b>{getRooms(stat)}</b>
                      </div>
                      <div className="col text-center">
                        <span>จำนวนที่นั่ง</span> <br />
                        <b>{getSeats(stat)}</b>
                      </div>
                      {!disabledAlevel && (
                        <div className="col">
                          <Link to={`/day/${INFO.dayIndex}/site`} className="btn-ghost">
                            จัดการสนามสอบ
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};
