import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import cn from 'classnames';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import ImportModal from './ImportModal';

// Components
import CONSTANTS from 'constants/constants';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  message,
  Input,
  Upload,
  Radio,
  Spin,
  Popconfirm,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// constants
import ROUNDS from 'constants/rounds';
import TCAS_STATUSES from 'constants/tcas_statuses';
import { useLocation, useParams } from 'react-router';
// import AdminDetailModal from './AdminModal';

const EXAM_TYPES = {
  pbt: 'กระดาษ',
  cbt: 'คอมพิวเตอร์',
};

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

const INIT_EMPTY_ROOM = {
  room_type: 'ปกติ',
  room_name: '',
  build_name: '',
  width: '',
  depth: '',
  floor: '',
};

const exampleFile = 'https://tcas67.s3.ap-southeast-1.amazonaws.com/examples/room-import.csv';

const download = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default () => {
  const { siteId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const adminConfig = useSelector((state) => state.configs || {});

  const [site, setSite] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [importModalVisible, setImportModalVisible] = useState(false);

  const [mode, setMode] = useState('display');
  const [isAddActive, setIsAddActive] = useState(false);

  const [tempRooms, setTempRooms] = useState([]);

  const [editedRoom, setEditedRoom] = useState(null);
  const [addedRoom, setAddedRoom] = useState({
    ...INIT_EMPTY_ROOM,
    exam_type: 'pbt',
  });
  const canEdit = adminConfig.center_active_test === 'CLOSED' ? false : true;

  const [addErrors, setAddedErrors] = useState({});

  const onAddedRoomChange = (field, value) => {
    setAddedRoom({ ...addedRoom, [field]: value });
  };

  const onEditedRoomChange = (field, value) => {
    setEditedRoom({ ...editedRoom, [field]: value });
  };

  const getSchool = async () => {
    const _site = await callApi({ url: `/exam-sites/${siteId}` });
    setSite(_site);
  };
  const getRooms = async () => {
    const _rooms = await callApi({ url: `/exam-sites/${siteId}/rooms` });
    setRooms(_rooms.data);
    return _rooms.data;
  };

  useEffect(() => {
    getSchool();
    getRooms();
  }, []);

  if (!site) {
    return <div />;
  }

  const onClickEditButton = () => {
    setMode('sortable');
    setTempRooms([...rooms]);
  };
  const onCancelEdit = () => {
    setMode('display');
    setRooms(tempRooms);
  };

  const onDelete = async (roomId) => {
    setLoading(true);
    await callApi({ url: `/exam-sites/${siteId}/rooms/${roomId}`, method: 'delete' });
    const _rooms = await getRooms();
    setTempRooms([..._rooms]);
    setLoading(false);
  };

  const onSaveEditRoom = async () => {
    setLoading(true);
    try {
      await callApi({ url: `/exam-sites/${siteId}/rooms/${editedRoom._id}`, method: 'put', body: editedRoom });
    } catch (err) {
      Modal.error({
        title: 'เกิดข้อผิดพลาด',
        content: _.get(err, 'reason.message'),
      });
    }

    const _rooms = await getRooms();
    setEditedRoom(null);
    setLoading(false);
  };

  const onSaveSequece = async () => {
    setLoading(true);
    const ids = rooms.map((room) => room._id);
    await callApi({ url: `/exam-sites/${siteId}/rooms`, method: 'put', body: { rooms: ids } });
    await getRooms();
    setLoading(false);
    setMode('display');
  };

  const validateInput = () => {
    const errors = {};
    if (!addedRoom.room_name) errors.room_name = 'กรุณาใส่ชื่อห้อง';
    if (!addedRoom.build_name) errors.build_name = 'กรุณาใส่ชื่ออาคาร';
    if (!addedRoom.width) errors.width = 'กรุณาใส่แนวกว้าง';
    if (!addedRoom.depth) errors.depth = 'กรุณาใส่แนวยาว';
    if (!addedRoom.floor) errors.floor = 'กรุณาใส่ชั้น';

    console.log(errors, _.isEmpty(errors));
    if (!_.isEmpty(errors)) {
      console.log('errors, _.isEmpty(errors)');
      setAddedErrors(errors);
      return false;
    }
    setAddedErrors({});
    return true;
  };

  const onAddRoomBtnClick = async () => {
    // validate input
    const validateResult = validateInput();

    if (validateResult) {
      setLoading(true);
      try {
        await callApi({ url: `/exam-sites/${siteId}/rooms`, method: 'post', body: addedRoom });
        await getRooms();
        setAddedRoom({
          ...INIT_EMPTY_ROOM,
          exam_type: 'pbt',
        });
        setLoading(false);
      } catch (err) {
        message.error(get(err, 'reason.message'));
        setLoading(false);
      }
    }
  };

  const renderTable = () => {
    return (
      <>
        <thead className="th-yellow">
          <tr>
            <th className="text-center">ลำดับ</th>
            <th>ชื่อห้องสอบ</th>
            <th>ชื่ออาคาร</th>
            <th>ชั้น</th>
            <th>แนวกว้าง</th>
            <th>แนวยาว</th>
            <th>จำนวนที่นั่งสอบ</th>
            <th>ประเภทห้อง</th>
            <th>ชนิดห้องสอบ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rooms.map((room, i) => {
            if (editedRoom && i === editedRoom.index) {
              return (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td>{editedRoom.room_name}</td>
                  <td>{editedRoom.build_name}</td>
                  <td>
                    <input
                      value={editedRoom.floor}
                      type="number"
                      className="form-control"
                      style={{ width: 60 }}
                      onChange={(e) => onEditedRoomChange('floor', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      value={editedRoom.width}
                      type="number"
                      className="form-control"
                      style={{ width: 60 }}
                      onChange={(e) => onEditedRoomChange('width', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      value={editedRoom.depth}
                      type="number"
                      className="form-control"
                      style={{ width: 60 }}
                      onChange={(e) => onEditedRoomChange('depth', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      value={editedRoom.seat}
                      type="number"
                      className="form-control"
                      style={{ width: 60 }}
                      onChange={(e) => onEditedRoomChange('seat', e.target.value)}
                    />
                  </td>
                  <td>
                    <Select
                      size={'large'}
                      value={editedRoom.room_type}
                      style={{ width: '100px' }}
                      onChange={(value) => onEditedRoomChange('room_type', value)}
                      disabled={editedRoom === 'cbt'}
                    >
                      <Select.Option value={'ปกติ'} key={'ปกติ'}>
                        ปกติ
                      </Select.Option>
                      <Select.Option value={'พิเศษ'} key={'พิเศษ'}>
                        พิเศษ
                      </Select.Option>
                    </Select>
                  </td>
                  <td>
                    <Select
                      size={'large'}
                      value={editedRoom.exam_type || 'pbt'}
                      style={{ width: '140px' }}
                      onChange={(value) => onEditedRoomChange('exam_type', value)}
                      disabled={true}
                    >
                      <Select.Option value={'pbt'} key={'pbt'}>
                        กระดาษ
                      </Select.Option>
                      <Select.Option value={'cbt'} key={'cbt'}>
                        คอมพิวเตอร์
                      </Select.Option>
                    </Select>
                  </td>
                  <td>
                    <a className="color-green text-underline" onClick={() => onSaveEditRoom()}>
                      บันทึก
                    </a>
                    <br />
                    <a className="color-gray" onClick={() => setEditedRoom(null)}>
                      ยกเลิก
                    </a>
                  </td>
                </tr>
              );
            }
            return (
              <tr key={i}>
                <td className="text-center">{i + 1}</td>
                <td>{room.room_name}</td>
                <td>{room.build_name}</td>
                <td>{room.floor}</td>
                <td>{room.width}</td>
                <td>{room.depth}</td>
                <td>{room.seat}</td>
                <td>{room.room_type}</td>
                <td>{EXAM_TYPES[room.exam_type || 'pbt']}</td>
                <td>
                  {!editedRoom && canEdit && (
                    <a
                      onClick={() => {
                        setEditedRoom({ ...room, index: i });
                      }}
                    >
                      แก้ไข
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };

  const renderSortableTable = () => {
    const DragHandle = SortableHandle(() => (
      <a>
        <i className="i-more"></i>
      </a>
    ));

    const SortableItem = SortableElement(({ value, sortIndex }) => {
      const room = value;
      return (
        <tr key={sortIndex}>
          <td>
            <DragHandle />
          </td>
          <td>
            <Popconfirm
              title="หากทำการลบสนามสอบนี้จะเป็นการลบข้อมูลห้องสอบทั้งหมดในแต่ละวันด้วย"
              onConfirm={() => onDelete(room._id)}
              okText="ยืนยันการลบ"
              cancelText="ยกเลิก"
            >
              <a>
                <i className="i-trash"></i>
              </a>
            </Popconfirm>
          </td>

          <td>{room.room_name}</td>
          <td>{room.build_name}</td>
          <td>{room.floor}</td>
          <td>{room.width}</td>
          <td>{room.depth}</td>
          <td>{room.seat}</td>
          <td>{room.room_type}</td>
          <td>{EXAM_TYPES[room.exam_type || 'pbt']}</td>
          <td></td>
        </tr>
      );
    });
    const SortableList = SortableContainer(({ items }) => {
      return (
        <tbody>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} sortIndex={index} value={value} />
          ))}
        </tbody>
      );
    });
    const onSortEnd = ({ oldIndex, newIndex }) => {
      setRooms(arrayMoveImmutable(rooms, oldIndex, newIndex));
    };

    return (
      <>
        <thead class="th-yellow">
          <tr>
            <th></th>
            <th></th>
            <th>ชื่อห้องสอบ</th>
            <th>ชื่ออาคาร</th>
            <th>ชั้น</th>
            <th>แนวกว้าง</th>
            <th>แนวยาว</th>
            <th>จำนวนที่นั่งสอบ</th>
            <th>ประเภทห้อง</th>
            <th>ชนิดห้องสอบ</th>
            <th></th>
          </tr>
        </thead>
        <SortableList items={rooms} onSortEnd={onSortEnd} helperClass="row-dragging" useDragHandle />
      </>
    );
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div>
          <div>
            <div className="t-main">
              <h3 className="exam-room-title">
                <i className="i-school"></i>
                {site.school_name}
              </h3>

              <div style={{ float: 'right' }}>
                {/* <Upload {...props} customRequest={dummyRequest}> */}

                {canEdit && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginRight: 10 }}
                    onClick={() => setImportModalVisible(true)}
                  >
                    อัปโหลดข้อมูลห้องสอบ
                  </Button>
                )}
                {/* </Upload> */}

                <Button
                  type="secondary"
                  icon={<DownloadOutlined />}
                  style={{ marginRight: 10 }}
                  onClick={async () => {
                    const { filePath } = await callApi({
                      url: `/exam-centers/${site.center_code}/sites/${siteId}/rooms/export`,
                      method: 'POST',
                    });
                    download(filePath);
                  }}
                >
                  ดาวน์โหลดห้องปัจจุบัน
                </Button>

                <Button
                  type="secondary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    window.open(exampleFile, '_blank');
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-file"
                  >
                    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                    <polyline points="13 2 13 9 20 9"></polyline>
                  </svg>{' '}
                  ไฟล์ตัวอย่าง
                </Button>
              </div>
              <div className="flex gap-16">
                <h2 className="color-blue">จัดการห้องสอบ</h2>
                {site.exam_type.indexOf('pbt') > -1 && (
                  <div className="action">
                    <a
                      className={cn('btn-add-toggle', { active: isAddActive })}
                      onClick={() => {
                        setIsAddActive(!isAddActive);
                      }}
                    >
                      เพิ่ม
                      <i className="i-down-button"></i>
                    </a>
                  </div>
                )}
              </div>

              {isAddActive && site.exam_type.indexOf('pbt') > -1 && (
                <div className="">
                  <div className="t-card border-orange">
                    <h3>เพิ่มห้องสอบ</h3>
                    <div className="sec-form">
                      <div className="col-25">
                        <div className="form-group">
                          <label>ชื่อห้อง</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ชื่อห้องสอบ"
                            value={addedRoom.room_name}
                            onChange={(e) => onAddedRoomChange('room_name', e.target.value)}
                          />
                          {addErrors.room_name && <div className="error text-red">{addErrors.room_name}</div>}
                        </div>
                      </div>
                      <div className="col-25">
                        <div className="form-group">
                          <label>ชื่ออาคาร</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ชื่ออาคาร"
                            value={addedRoom.build_name}
                            onChange={(e) => onAddedRoomChange('build_name', e.target.value)}
                          />
                          {addErrors.build_name && <div className="error text-red">{addErrors.build_name}</div>}
                        </div>
                      </div>
                      <div className="col-25">
                        <div className="form-group">
                          <label>ชั้น</label>
                          <input
                            value={addedRoom.floor}
                            type="number"
                            className="form-control"
                            onChange={(e) => onAddedRoomChange('floor', e.target.value)}
                          />
                          {addErrors.floor && <div className="error text-red">{addErrors.floor}</div>}
                        </div>
                      </div>
                      <div className="col-25">
                        <div className="form-group">
                          <label>แนวกว้าง</label>

                          <input
                            value={addedRoom.width}
                            type="number"
                            className="form-control"
                            onChange={(e) => onAddedRoomChange('width', e.target.value)}
                          />
                          {addErrors.width && <div className="error text-red">{addErrors.width}</div>}
                        </div>
                      </div>
                      <div className="col-25">
                        <div className="form-group">
                          <label>แนวยาว</label>

                          <input
                            value={addedRoom.depth}
                            type="number"
                            className="form-control"
                            onChange={(e) => onAddedRoomChange('depth', e.target.value)}
                          />
                          {addErrors.depth && <div className="error text-red">{addErrors.depth}</div>}
                        </div>
                      </div>

                      <div className="col-25">
                        <div className="form-group">
                          <label>ประเภทห้อง</label>
                          <div>
                            <Select
                              size={'large'}
                              value={addedRoom.room_type}
                              style={{ width: '100%' }}
                              onChange={(value) => onAddedRoomChange('room_type', value)}
                              disabled={addedRoom === 'cbt'}
                            >
                              <Select.Option value={'ปกติ'} key={'ปกติ'}>
                                ปกติ
                              </Select.Option>
                              <Select.Option value={'พิเศษ'} key={'พิเศษ'}>
                                พิเศษ
                              </Select.Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="col-25">
                        <div className="form-group">
                          <label>ชนิดห้องสอบ</label>
                          <div>
                            <Select
                              size={'large'}
                              value={addedRoom.exam_type || 'pbt'}
                              style={{ width: '100%' }}
                              onChange={(value) => onAddedRoomChange('exam_type', value)}
                              disabled={true}
                            >
                              <Select.Option value={'pbt'} key={'pbt'}>
                                กระดาษ
                              </Select.Option>
                              <Select.Option value={'cbt'} key={'cbt'}>
                                คอมพิวเตอร์
                              </Select.Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="col-25 col-action">
                        <a className="btn-main" onClick={onAddRoomBtnClick}>
                          <i className="i-add"></i> เพิ่มห้องเรียน
                        </a>
                        <div> </div>
                      </div>
                      <div className="col-25"></div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex gap-16">
                <i className="i-con">
                  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.102 1.005a1.926 1.926 0 0 0-.425.018L3.719 2.56A.86.86 0 0 0 3 3.42V20.58c0 .43.306.795.719.859l9.958 1.537a1.923 1.923 0 0 0 1.576-.468c.362-.321.586-.766.654-1.246h3.124c1.077 0 1.969-.918 1.969-2.026V4.763c0-1.108-.892-2.026-1.969-2.026h-3.124a2.031 2.031 0 0 0-.654-1.245 1.94 1.94 0 0 0-1.151-.487Zm1.835 3.469h3.094c.166 0 .282.118.282.29v14.473c0 .17-.116.29-.282.29h-3.093V4.473ZM12 11.42c.622 0 1.125.518 1.125 1.158 0 .64-.503 1.158-1.125 1.158s-1.125-.518-1.125-1.158c0-.64.503-1.158 1.125-1.158Z"
                      fill="#FFB600"
                    />
                  </svg>
                </i>
                <h3>ห้องสอบ {rooms.length} ห้อง</h3>
              </div>
              <table className="t-table t-table-striped">
                {mode === 'display' && renderTable()}
                {mode === 'sortable' && renderSortableTable()}
              </table>
              <br />

              {canEdit && mode === 'display' && editedRoom === null && (
                <a className="btn-ghost pointer" onClick={() => onClickEditButton()}>
                  แก้ไขลำดับหรือลบข้อมูล
                </a>
              )}
              {canEdit && mode === 'sortable' && (
                <div className="flex items-center gap-16">
                  <a className="btn-main btn-radius" onClick={() => onSaveSequece()}>
                    บันทึกการแก้ไขอันดับ
                  </a>

                  <a className="_h" onClick={() => onCancelEdit()}>
                    ยกเลิกการแก้ไข
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {importModalVisible && canEdit && (
          <ImportModal
            setIsVisible={setImportModalVisible}
            isVisible={importModalVisible}
            siteCode={siteId}
            siteSchoolCode={site.site_school_code}
            config={{}}
            setLoading={setLoading}
            loading={loading}
            getRooms={getRooms}
            onClose={() => {
              setImportModalVisible(false);
            }}
          ></ImportModal>
        )}
      </Spin>
    </div>
  );
};
