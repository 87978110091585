import React, { useEffect, useState } from 'react';
import { Modal, Button, Select } from 'antd';
import cn from 'classnames';
import callApi from 'helpers/callapi';
import ROUNDS from 'constants/rounds';

const download = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const DownloadModal = ({ setIsVisible, centerCode, isVisible }) => {
  const [onlySubmit, setOnlySubmit] = useState(true);
  const [type, setType] = useState('site');

  const createExportFile = async () => {
    let url;
    switch (type) {
      case 'site':
        url = `/exam-centers/${centerCode}/sites/export`;
        break;
      case 'center':
        url = `/exam-centers/${centerCode}/export`;
        break;
      case 'room':
        url = `/exam-centers/${centerCode}/rooms/export`;

        break;
      case 'roomday':
        url = `/exam-centers/${centerCode}/days/export`;
        break;
    }

    const { filePath } = await callApi({
      url: url,
      method: 'POST',
    });
    download(filePath);
    setIsVisible(false);
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={() => {
        setIsVisible(false);
      }}
      width={720}
      footer={null}
    >
      <h2>ดาวน์โหลดข้อมูลสนามสอบ</h2>
      <br />

      <div className="sec-form">
        <div className="col-25">ชนิดไฟล์ที่ต้องการดาวน์โหลด</div>
        <div className="col-25">
          <Select onChange={(value) => setType(value)} value={type} size={'large'}>
            <Select.Option value={'site'}>ข้อมูลสนามสอบ</Select.Option>
            {/* <Select.Option value={'center'}>ข้อมูลศูนย์สอบ</Select.Option> */}
            <Select.Option value={'room'}>ข้อมูลห้องสอบ</Select.Option>
            <Select.Option value={'roomday'}>ข้อมูลห้องสอบทุกวัน</Select.Option>
          </Select>
        </div>
      </div>
      <div>
        <Button
          block
          type="primary"
          onClick={() => {
            createExportFile();
          }}
        >
          ดาวน์โหลดข้อมูล
        </Button>
      </div>
    </Modal>
  );
};

export default DownloadModal;
