import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import theme from './theme.js';
import Login from 'containers/Login';
import CountDown from 'containers/Countdown';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/Admin/ResetPassword';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Route } from 'react-router-dom';

const INITIAL_STATE = {
  user: null,
  universities: [],
  courses: {},
  subjects: [],
};
const reducer = (state = INITIAL_STATE, { type, data, university }) => {
  switch (type) {
    case 'UPDATE_USER':
      return { ...state, userLoaded: true, user: data };
    case 'UPDATE_UNIVERSITIES':
      return { ...state, universities: data };
    case 'UPDATE_COURSES': {
      const _courses = state.courses;
      _courses[university] = data;
      return { ...state, courses: { ..._courses } };
    }
    case 'UPDATE_SCHOOLS': {
      const _schools = state.schools;

      return { ...state, schools: data };
    }
    case 'UPDATE_SUBJECTS':
      return { ...state, subjects: data };
    case 'UPDATE_CONFIGS':
      return { ...state, configs: data };
    case 'UPDATE_SITES':
      return { ...state, sites: data };

    default:
      return state;
  }
};

const middleware = [];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/credential" component={Login} />
        <Route exact path="/coming-soon" component={CountDown} />
        <Route exact path="/forgot-password" component={ForgotPassword} />

        <Route path="/" component={App} />
      </Router>
    </Provider>
  </ThemeProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
