import React from 'react';

const Contact = () => {
  return (
    <div className="t-main">
      <h1>คู่มือการใช้งาน</h1>
      <div className="sec-box">
        <p>สามารถดูรายละเอียดและค้นหาข้อมูลการใช้งานได้ที่</p>
        <h3 className="_space">
          <strong>
            <a href="https://assets.mytcas.com/center/T66-Center-Docs.pdf" target="_blank">
              คู่มือการใช้งาน →
            </a>
          </strong>
        </h3>
        {/* <p>โดยใช้รหัสผ่านดังนี้</p>
        <code>foradminonly</code> */}
      </div>
    </div>
  );
};

export default Contact;
