import React, { useEffect, useState } from 'react';
import { Spin, Modal, message } from 'antd';
import csv from 'csv-parser';
import fileReaderStream from 'filereader-stream';
import _, { find, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import useImportRoom from './useImportRoom';
import callApi from 'helpers/callapi';

const EXAM_TYPES = {
  pbt: 'กระดาษ',
  cbt: 'คอมพิวเตอร์',
};

const ImportModal = ({
  isVisible,
  config,
  loading,
  siteCode,
  siteSchoolCode,
  setIsVisible,
  setLoading,
  getRooms,
  onClose,
}) => {
  const filePath = '';
  // const {
  //   uploadRef,
  //   setSelectedFile,
  //   setSiteCode,
  //   // importSubjects,
  //   setCount,
  //   count,
  //   onUpload,
  //   uploading,
  //   uploadProgress,
  //   importErrors,
  // } = useImportRoom(config);
  // { type: 'import', onUploadDone: () => {} , siteCode: siteCode}
  const [count, setCount] = useState(0);
  const me = useSelector((state) => state.user);

  const [fileErrors, setFileErrors] = useState([]);
  const [uploadedFile, setUploadedFile] = useState();

  const [rooms, setRooms] = useState([]);

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    let results = [];
    const headers = [];
    let count = 0;

    fileReaderStream(file)
      .pipe(
        csv({
          mapHeaders: ({ header, index }) => {
            headers.push(header.toLowerCase().trim());
            return header.toLowerCase().trim();
          },
        })
      )
      .on('data', (data) => {
        if (data.site_school_code === siteSchoolCode) {
          data.site_code = siteCode;
          data.exam_type = me.exam_type;
          results.push(data);
          count++;
        }
      })
      .on('end', () => {
        // const  = [];

        results = _.sortBy(results, '-room_order');
        results.forEach((room, i) => {
          room.room_order = i + 1;
        });
        const subjectsObj = [];
        const errors = [];

        if (errors.length > 0) {
          setFileErrors(errors);
          setCount(0);
          e.target.value = null;
          setRooms([]);
          setUploadedFile(null);
          return;
        } else {
          setCount(count);
          setRooms(results);
          setUploadedFile(file);
          setFileErrors([]);
        }
      });
    // Prevent upload
    return false;
  };

  const onUpload = async () => {
    if (!uploadedFile) {
      message.error('กรุณาเลือกไฟล์ที่ต้องการนำเข้าข้อมูลห้องสอบ');
      return;
    }
    if (rooms.length === 0) {
      message.error('ไฟล์ที่ท่านนำเข้าไม่มีห้องสอบสำหรับสนามสอบนี้ กรุณาเช็คไฟล์ที่นำเข้าอีกรอบ');
      return;
    }

    setLoading(true);
    try {
      await callApi({
        url: `/exam-sites/${siteCode}/import`,
        method: 'post',
        body: { rooms: rooms },
      });
      await getRooms();

      setLoading(false);
      onClose();
    } catch (err) {
      message.error(get(err, 'reason.message'));
      setLoading(false);
    }
  };

  // useEffect(() => onErrorsChange(importErrors), [importErrors]);

  const renderTable = () => {
    if (!rooms.length) {
      return <div></div>;
    }
    console.log(rooms);

    return (
      <>
        <table className="t-table t-table-striped">
          <thead className="th-yellow">
            <tr>
              <th>ชื่อห้องสอบ</th>
              <th>ชื่ออาคาร</th>
              <th>ชั้น</th>
              <th>แนวกว้าง</th>
              <th>แนวยาว</th>
              <th>จำนวนที่นั่งสอบ</th>
              <th>ประเภทห้อง</th>
              <th>ชนิดห้องสอบ</th>
              {me.exam_type === 'cbt' && <th>venue_id</th>}
              {me.exam_type === 'cbt' && <th>room_id</th>}
            </tr>
          </thead>
          <tbody>
            {rooms.map((room, i) => {
              return (
                <tr key={i}>
                  <td>{room.room_name}</td>
                  <td>{room.build_name}</td>
                  <td>{room.floor}</td>
                  <td>{room.width}</td>
                  <td>{room.depth}</td>
                  <td>{room.seat}</td>
                  <td>{room.room_type}</td>
                  <td>{EXAM_TYPES[room.exam_type || 'pbt']}</td>
                  {me.exam_type === 'cbt' && <td>{room.venue_id}</td>}
                  {me.exam_type === 'cbt' && <td>{room.room_id}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <Modal visible={isVisible} onCancel={() => setIsVisible(false)} width={900} footer={null}>
      <Spin spinning={loading} delay={0}>
        <h2>นำเข้าห้องสอบ {siteSchoolCode}</h2>
        <br />

        {Boolean(fileErrors.length) && (
          <div className="sec-error">
            <p>พบข้อผิดพลาดในไฟล์ CSV ดังนี้ </p>
            <ul>
              {fileErrors.map(({ index, message }, _idx) => (
                <li key={_idx}>
                  แถวที่ <b className="index">{index}</b> <b className="msg">{message.subject}</b> {message.correction}
                </li>
              ))}
            </ul>
          </div>
        )}

        <p>เลือกไฟล์ที่ต้องการนำเข้า แล้วคลิกปุ่มนำเข้าข้อมูล</p>

        <div className="sec-form">
          <div className="col-75">
            <input type="file" accept=".csv" onChange={(e) => onSelectFile(e)} />
            <div className={'t-progress'}>
              <b />
            </div>
          </div>
          <div className="col-25">
            <a className="btn-main" onClick={onUpload}>
              <span>
                <i className="i-upload" /> นำเข้าข้อมูล
              </span>
            </a>
          </div>
        </div>
        {renderTable()}
      </Spin>
    </Modal>
  );
};

export default ImportModal;
