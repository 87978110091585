module.exports = {
  labels: {
    TGAT: 'ความถนัดทั่วไป (TGAT)',
    TPAT2: 'ความถนัดศิลปกรรมศาสตร์ (TPAT2)',
    TPAT3: 'ความถนัดวิทยาศาสตร์ เทคโนโลยี วิศวกรรมศาสตร์ (TPAT3)',
    TPAT4: 'ความถนัดสถาปัตยกรรมศาสตร์ (TPAT4)',
    TPAT5: 'ความถนัดครุศาสตร์-ศึกษาศาสตร์ (TPAT 5)',

    Math1: 'Math1 คณิตศาสตร์ 1',
    Math2: 'Math2 คณิตศาสตร์ 2',
    Sci: 'Sci วิทยาศาสตร์',
    Phy: 'Phy ฟิสิกส์',
    Chem: 'Chem เคมี',
    Bio: 'Bio ชีววิทยา',
    Soc: 'Soc สังคมศึกษา',
    Thai: 'Thai ไทย',
    Eng: 'Eng อังกฤษ',
    Fre: 'Fre ฝรั่งเศส',
    Ger: 'Ger เยอรมัน',
    Jap: 'Jap ญี่ปุ่น',
    Kor: 'Kor เกาหลี',
    Chi: 'Chi จีน',
    Bal: 'Bal บาลี',
    Spn: 'Spn สเปน',
  },
  scores: {},
  index: {
    TGAT: 1,
    TPAT2: 3,
    TPAT3: 4,
    TPAT4: 5,
    TPAT5: 6,
  },
};
