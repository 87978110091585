import React, { useEffect, useState } from 'react';
import { Tabs, Collapse } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import EXAM_SUBJECTS from 'constants/exam-subjects';

const { Panel } = Collapse;

const TGAT_TPAT_SUBJECTS = ['TGAT', 'TPAT2', 'TPAT3', 'TPAT4', 'TPAT5'];

const Exam = () => {
  let { citizenId } = useParams();

  const dispatch = useDispatch();
  const [examApplications, setExamApplications] = useState([]);

  const subjects = useSelector((state) => state.subjects || []);
  const sites = useSelector((state) => state.sites || []);

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const fetchSites = async () => {
    if (sites.length > 0) {
      return;
    }
    try {
      const _sites = await callApi({ url: '/exam-sites' });
      const sitesBySiteCode = _.keyBy(_sites, 'site_code');
      dispatch({ type: 'UPDATE_SITES', data: sitesBySiteCode });
    } catch (error) {
      console.log('errror', error);
    }

    // setSites(_sites.data);
  };

  useEffect(() => {
    fetchSubjects();
    fetchSites();
  }, []);

  const fetchApplication = async () => {
    const { count, data } = await callApi({
      url: '/exams/centers',
      query: { citizen_id: citizenId },
    });
    setExamApplications(data);
  };

  useEffect(() => {
    if (citizenId) {
      fetchApplication();
    }
  }, []);

  if (!examApplications || examApplications.length === 0) {
    return <div></div>;
  }

  const appsGroupByDay = _.groupBy(examApplications, 'day');
  const subjectsByKey = _.keyBy(subjects, 'key');
  // const repliesByRound = _.groupBy(replies, 'type');
  const cbtExamApplication = _.find(examApplications, { selected_exam_type: 'cbt' });
  const student = examApplications[0].student;
  const masterApp = examApplications[0];

  const alevelApp = _.find(examApplications, (item) => !TGAT_TPAT_SUBJECTS.includes(item.subject_name));

  const tgpApp = _.find(examApplications, (item) => TGAT_TPAT_SUBJECTS.includes(item.subject_name));

  // console.log(repliesByRound);

  return (
    <div>
      <div className="t-card border-orange">
        <h2 className="t-title">
          {student.title} {student.first_name} {student.last_name}
        </h2>
        <div className="flex flex-wrap">
          <div>
            รหัสบัตรประชาชน: <b>{citizenId}</b>
          </div>
          {tgpApp && (
            <div>
              ที่นั่งสอบ TGAT-TPAT: <b>{tgpApp.app_id}</b>
            </div>
          )}
          {alevelApp && (
            <div>
              ที่นั่งสอบ A-Level: <b>{alevelApp.app_id}</b>
            </div>
          )}

          <div>
            เบอร์โทรศัพท์: <b>{student.telephone}</b>
          </div>
          <div>
            อีเมล: <b>{student.email}</b>
          </div>
        </div>
      </div>

      {cbtExamApplication && (
        <div>
          <div className="t-card border-orange exam-dates">
            <h2>ทดลองสอบด้วยเครื่องคอมพิวเตอร์</h2>
            <ul className="r-history last">
              <li>
                วันทดลองสอบ <b>{cbtExamApplication.test_system_date}</b>
              </li>
              <li>
                เวลา <b>{cbtExamApplication.test_system_time}</b>
              </li>
              <li>
                สนามสอบ <b>{cbtExamApplication.test_system_site_name}</b>
              </li>
              <li>
                อาคาร{' '}
                <b>
                  {cbtExamApplication.test_system_building.startsWith('อาคาร')
                    ? cbtExamApplication.test_system_building.replace('อาคาร', '')
                    : cbtExamApplication.test_system_building}
                </b>{' '}
                ห้อง{' '}
                <b>
                  {cbtExamApplication.test_system_room.startsWith('ห้อง')
                    ? cbtExamApplication.test_system_room.replace('ห้อง', '')
                    : cbtExamApplication.test_system_room}
                </b>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="exam-dates flex gap-16">
        {_.map(appsGroupByDay, (apps, day) => {
          return (
            <div className="col-33 _space">
              <h2>วันที่ {day}</h2>
              {apps.map((app, i) => {
                const site = sites[app.selected_place] || {
                  app_id: '-',
                  school_name: '-',
                };
                const isCbt = app.cbt_place_1;

                const subject = subjectsByKey[app.subject_name.toLowerCase()] || {};
                // console.log(subjectsByKey, subject, site);

                return (
                  <Collapse>
                    <Panel
                      header={
                        <div>
                          <h3>{subject.label}</h3>
                          <div>สถานที่สอบ {site.school_name}</div>
                        </div>
                      }
                      key={i}
                    >
                      <ul key={app._id} className="r-history">
                        <li>
                          เลขที่นั่งสอบ <b>{app.app_id || 'ยังไม่ได้จัดสรร'}</b>
                        </li>

                        <li>
                          สนามสอบ{' '}
                          <b>
                            {site.school_name} {site.site_code ? `(${site.site_code})` : ''}
                          </b>
                        </li>
                        <li>
                          อาคาร <b>{app.building_name || '-'}</b> &nbsp;&nbsp; ชั้น <b>{app.floor || '-'}</b> ห้อง{' '}
                          <b>{app.room_name || '-'}</b>
                        </li>
                        <li>
                          ตำแหน่งที่นั่ง{' '}
                          <b>
                            แถว <small>(Row)</small> {app.row || '-'} • ตอน <small>(Column)</small> {app.col || '-'}
                          </b>
                        </li>
                        <li>
                          จังหวัด <b>{site.province || '-'}</b>
                        </li>
                        <li>
                          ความต้องการพิเศษ <b>{app.special_request || '-'}</b>
                        </li>
                      </ul>

                      {isCbt && (
                        <ul className="r-history">
                          <li>
                            <h3>สถานที่สอบคอมพิวเตอร์ที่เลือก</h3>
                          </li>

                          {_.times(5, _.constant(null)).map((a, index) => {
                            const place = _.get(sites, `${app[`cbt_place_${index + 1}`]}.school_name`);

                            return (
                              <li>
                                อันดับ {index + 1}: {place}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      <ul className="r-history last">
                        <li className="hr">
                          <h3>สถานที่สอบกระดาษที่เลือก</h3>
                        </li>

                        {_.times(5, _.constant(null)).map((a, index) => {
                          const place = _.get(sites, `${app[`place_${index + 1}`]}.school_name`);

                          return (
                            <li>
                              อันดับ {index + 1}: {place}
                            </li>
                          );
                        })}
                      </ul>
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Exam;
