import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  message,
  Input,
  Upload,
  Radio,
  Spin,
  Checkbox,
  Tooltip,
} from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';

import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

// constants
import { TGATTPATINFOS, ALEVELINFOS } from 'constants/exam-days';

// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

export default () => {
  const EXAMDAYS = TGATTPATINFOS.concat(ALEVELINFOS);
  const { day } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('display'); // display, edit, sort
  const adminConfig = useSelector((state) => state.configs || {});

  const [selectedSites, setSelectedSites] = useState([]);
  const [sites, setSites] = useState([]);

  const [tempSelectedSites, setTempSelectedSite] = useState([]);

  const dayInfo = find(EXAMDAYS, { dayIndex: parseInt(day, 10) });
  const [center, setCenter] = useState(null);

  const [isOpenDuplicateMenu, setIsOpenDuplicateMenu] = useState(false);

  const [duplicateDayValue, setDuplicateDayValue] = useState(day === '1' ? 2 : 1);

  let canEditTestDays;
  switch (adminConfig.center_active_test) {
    case 'TGATTPAT':
      canEditTestDays = ['1', '2', '3'];
      break;
    case 'ALEVEL':
      canEditTestDays = ['4', '5', '6'];
      break;
    default:
      canEditTestDays = [];
      break;
  }
  const canEdit = canEditTestDays.indexOf(day) > -1;
  console.log(adminConfig.center_active_test, canEdit, canEditTestDays);

  useEffect(() => {
    if (day === '6' || day === '7') {
      setDuplicateDayValue(5);
    } else if (day === '5') {
      setDuplicateDayValue(6);
    }
  }, [day]);

  const onCheckAll = () => {
    if (sites.length === tempSelectedSites.length) {
      setTempSelectedSite([]);
    } else {
      setTempSelectedSite(sites.map((site) => site.site_code));
    }
  };

  const onCheckSite = (siteCode) => {
    if (tempSelectedSites.indexOf(siteCode) === -1) {
      setTempSelectedSite([...tempSelectedSites, siteCode]);
      return;
    }
    let newSelectedSite = _.filter(tempSelectedSites, (_site_code) => _site_code !== siteCode);
    setTempSelectedSite(newSelectedSite);
  };

  const getSites = async () => {
    const _sites = await callApi({ url: '/exam-centers/me/sites' });
    setSites(_sites.data);
  };

  const getSelectedSites = async () => {
    const _sites = await callApi({ url: `/exam-centers/me/days/${day}/sites` });
    setSelectedSites(_sites);
  };

  const onClickEditButton = () => {
    const _tempSelectedSites = selectedSites.map((site) => site.site_code);
    setMode('edit');
    setTempSelectedSite(_tempSelectedSites);
  };
  const onCancelEdit = () => {
    setMode('display');
    const _tempSelectedRooms = selectedSites.map((site) => site.site_code);
    setTempSelectedSite(_tempSelectedRooms);
  };

  const onClickUpdateSiteButton = async () => {
    setLoading(true);
    // const ids = rooms.map((room) => room._id);
    try {
      await callApi({
        url: `/exam-centers/${me.center_code}/days/${day}/sites`,
        method: 'post',
        body: { sites: tempSelectedSites },
      });
      await getSelectedSites();
      await getCenter();
      setLoading(false);
      setMode('display');
    } catch (err) {
      message.error(get(err, 'reason[0].message'));
      setLoading(false);
    }
  };
  const getCenter = async () => {
    const center = await callApi({ url: '/exam-centers/me' });
    setCenter(center);
  };

  const onSubmitDuplicateData = async () => {
    setLoading(true);
    // const ids = rooms.map((room) => room._id);
    await callApi({
      url: `/exam-centers/${me.center_code}/days/${day}/duplicate/${duplicateDayValue}`,
      method: 'post',
    });
    await getSelectedSites();
    await getCenter();
    setLoading(false);
    setMode('display');
  };

  useEffect(() => {
    getSites();
    getSelectedSites();
    getCenter();
  }, []);

  const renderTable = () => {
    return (
      <>
        <thead className="th-orange">
          <tr>
            <th>ลำดับ</th>
            <th>ชื่อสนามสอบ</th>
            <th>รหัส</th>
            <th>จังหวัด</th>
            <th>อำเภอ</th>
            <th className="text-right">ห้อง</th>
            <th className="text-right">ที่นั่งสอบ</th>
            <th className="text-right">
              <Tooltip title="ห้องสอบปกติ กระดาษ">
                <i className="i-paper"></i>
              </Tooltip>
            </th>
            <th className="text-center">
              <Tooltip title="ห้องสอบสำหรับผู้ต้องการความช่วยเหลือพิเศษ กระดาษ">
                <i className="i-disabled"></i>
              </Tooltip>
            </th>
            <th className="text-center">
              <Tooltip title="ห้องสอบปกติ คอมพิวเตอร์">
                <i className="i-computer"></i>
              </Tooltip>
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedSites.map((site, i) => {
            const stat = get(site, `info_by_days[day${day}]`);
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{site.school_name}</td>
                <td>{site.site_school_code}</td>
                <td>{site.province}</td>
                <td>{site.amphur}</td>
                <td className="_nowrap text-right">
                  {get(stat, 'rooms') + (get(stat, 'special_rooms') || 0) + (get(stat, 'cbt_rooms') || 0)}
                </td>
                <td className="_nowrap text-right">
                  {get(stat, 'seats') + (get(stat, 'special_seats') || 0) + (get(stat, 'cbt_seats') || 0)}
                </td>
                <td className="_nowrap text-center">
                  {get(stat, 'rooms')} : {get(stat, 'seats')}
                </td>
                <td className="_nowrap text-center">
                  {get(stat, 'special_rooms')} : {get(stat, 'special_seats')}
                </td>
                <td className="_nowrap text-center">
                  {get(stat, 'cbt_rooms')} : {get(stat, 'cbt_seats')}
                </td>
                <td>
                  <Link to={`/day/${dayInfo.dayIndex}/site/${site.site_code}/room`} className="btn-manage">
                    {canEdit ? 'จัดการห้องสอบ' : 'ดูข้อมูลห้องสอบ'}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };

  const renderEditTable = () => {
    let runningNumber = 0;
    return (
      <>
        <thead className="th-orange">
          <tr>
            <th>
              <Checkbox checked={tempSelectedSites.length === sites.length} onChange={() => onCheckAll()}></Checkbox>
            </th>
            <th>ลำดับ</th>
            <th>ชื่อสนามสอบ</th>
            <th>รหัส</th>
            <th>จังหวัด</th>
            <th>อำเภอ</th>
            <th>ห้อง</th>
            <th>
              <i className="i-paper"></i>
            </th>
            <th>
              <i className="i-disabled"></i>
            </th>
            <th>
              <i className="i-computer"></i>
            </th>
            <th>ที่นั่งสอบ</th>
          </tr>
        </thead>
        <tbody>
          {sites.map((site, i) => {
            let stat = get(site, `info_by_days[day${day}]`);
            console.log(get(stat, 'rooms'), get(stat, 'special_rooms'), get(stat, 'cbt_rooms'));
            if (!(get(stat, 'rooms') || get(stat, 'special_rooms') || get(stat, 'cbt_rooms'))) {
              stat = get(site, `stats`);
            }

            const isChecked = tempSelectedSites.indexOf(site.site_code) > -1;
            if (isChecked) {
              runningNumber++;
            }
            return (
              <tr key={i}>
                <td>
                  <Checkbox checked={isChecked} onChange={() => onCheckSite(site.site_code)}></Checkbox>
                </td>
                <td>{isChecked && runningNumber} </td>
                <td>{site.school_name}</td>
                <td>{site.site_school_code}</td>
                <td>{site.province}</td>
                <td>{site.amphur}</td>

                <td className="_nowrap">
                  {(get(stat, 'rooms') || 0) + (get(stat, 'special_rooms') || 0) + (get(stat, 'cbt_rooms') || 0)}
                </td>
                <td className="_nowrap">
                  {(get(stat, 'seats') || 0) + (get(stat, 'special_seats') || 0) + (get(stat, 'cbt_seats') || 0)}
                </td>

                <td className="_nowrap">
                  {get(stat, 'rooms')} : {get(stat, 'seats')}
                </td>
                <td className="_nowrap">
                  {get(stat, 'special_rooms')} : {get(stat, 'special_seats')}
                </td>
                <td className="_nowrap">
                  {get(stat, 'cbt_rooms')} : {get(stat, 'cbt_seats')}
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };

  const dayStat = get(center, `info_by_days.day${day}`);

  const duplicateOptions = ['1', '2', '3', '4'].indexOf(day) > -1 ? TGATTPATINFOS : ALEVELINFOS;

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>จัดการสนามสอบ</h1>
          <div className="round">
            <div className="round-date">
              <span className="number">{dayInfo.index}</span> <span className="date">{dayInfo.date}</span>
            </div>
            <div className="round-time">
              {dayInfo.examSlots.map((slot) => {
                return (
                  <div className="item">
                    {slot.time} <span className="color-blue">{slot.subject}</span>
                  </div>
                );
              })}
            </div>
            <div className="round-body">
              <div className="col text-center">
                <span>จำนวนสนามสอบ</span> <br />
                {me.exam_type === 'cbt' && <b>{get(dayStat, `cbt_sites`) || 0}</b>}
                {me.exam_type === 'pbt' && <b>{get(dayStat, `pbt_sites`) || 0}</b>}
                {me.exam_type === 'cbt_pbt' && <b>{get(dayStat, `cbt_sites`) + get(dayStat, `pbt_sites`) || 0}</b>}
              </div>
              <div className="col text-center">
                <span>จำนวนห้องสอบ</span> <br />
                {me.exam_type === 'cbt' && <b>{get(dayStat, `cbt_rooms`) || 0}</b>}
                {me.exam_type === 'pbt' && <b>{get(dayStat, `rooms`) + get(dayStat, `special_rooms`)}</b>}
                {me.exam_type === 'cbt_pbt' && (
                  <b>{get(dayStat, `cbt_rooms`) + get(dayStat, `rooms`) + get(dayStat, `special_rooms`) || 0}</b>
                )}
              </div>
              <div className="col text-center">
                <span>จำนวนที่นั่ง</span> <br />
                {me.exam_type === 'cbt' && <b>{get(dayStat, `cbt_seats`) || 0}</b>}
                {me.exam_type === 'pbt' && <b>{get(dayStat, `seats`) + get(dayStat, `special_seats`)}</b>}
                {me.exam_type === 'cbt_pbt' && (
                  <b>{get(dayStat, `cbt_seats`) + get(dayStat, `seats`) + get(dayStat, `special_seats`) || 0}</b>
                )}
              </div>
            </div>
          </div>
          <div className="_space"></div>
          <div className="flex justify-between">
            <h2>
              <i className="i-school-orange"></i> เลือก {selectedSites.length} จาก {sites.length} แห่ง
            </h2>

            {canEdit && (
              <a
                class="venue-duplicate-toggle color-green"
                onClick={() => setIsOpenDuplicateMenu(!isOpenDuplicateMenu)}
              >
                ต้องการคัดลอกข้อมูลจากวันสอบอื่น ?
              </a>
            )}
          </div>
          {isOpenDuplicateMenu && (
            <div class="venue-duplicate-collapse active">
              <div class="t-card border-orange">
                <h3>คัดลอกข้อมูลจากวันสอบอื่น</h3>
                <div class="flex">
                  <div class="col-1 min-w-20">
                    <div class="form-group">
                      <label>เลือกวันสอบ</label>
                      <Select
                        value={duplicateDayValue}
                        onChange={async (value) => {
                          setDuplicateDayValue(value);
                        }}
                        size={'large'}
                        style={{ width: '100%' }}
                      >
                        {duplicateOptions.map((INFO) => {
                          if (INFO.dayIndex.toString() === day) {
                            return <></>;
                          }
                          return <Select.Option value={INFO.dayIndex}>{INFO.date}</Select.Option>;
                        })}
                      </Select>
                    </div>
                  </div>
                  <div class="col-action col-3">
                    <small>
                      <b>คำเตือน</b> เมื่อคลิก “ยืนยัน” ระบบจะลบข้อมูล
                      <br />
                      ที่เลือกไว้ก่อนหน้าทั้งหมด แล้วทำการคัดลอกข้อมูล
                    </small>
                  </div>
                  <div class="col-action col-1">
                    <a class="btn-main" onClick={() => onSubmitDuplicateData()}>
                      <i class="i-add"></i> ยืนยัน
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}

          <table className="t-table t-table-striped">
            {mode === 'display' && renderTable()}
            {mode === 'edit' && renderEditTable()}
          </table>

          <div className="_space"></div>

          {mode === 'display' && canEdit && (
            <a className="btn-ghost pointer" onClick={() => onClickEditButton()}>
              เพิ่ม-ลด สนามสอบ
            </a>
          )}
          {mode === 'edit' && (
            <>
              <a className="btn-main btn-radius" onClick={() => onClickUpdateSiteButton()}>
                บันทึกข้อมูล
              </a>
              <a className="_h" onClick={() => onCancelEdit(null)}>
                ยกเลิก
              </a>
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};
