import React from 'react';
import Counter from 'components/Counter';

const Contact = () => {
  return (
    <div>
      <div className="t-body">
        <div className="t-login">
          <div className="t-form">
            <div className="text-center">
              <a href="/">
                <img src="/img/logo.svg" alt="Logo" width="194" height="45" />
              </a>
              <h1>ระบบจัดการสนามสอบจะเปิดในอีก</h1>
            </div>
            <div className="_space">
              <div className="t-main">
                <Counter examRound={'ALEVEL'}></Counter>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
