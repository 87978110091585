module.exports = {
  TGATTPATINFOS: [
    {
      index: 1,
      dayIndex: 1,

      date: 'เสาร์ ที่ 9 ธ.ค. 66',
      examSlots: [
        { time: '09.00 - 12.00 น.', subject: 'TPAT5' },
        { time: '13.00 - 16.00 น.', subject: 'TPAT2 (TPAT21 / TPAT22 / TPAT23)' },
      ],
    },

    {
      index: 2,
      dayIndex: 2,

      date: 'อาทิตย์ ที่ 10 ธ.ค. 66',
      examSlots: [
        { time: '09.00 - 12.00 น.', subject: 'TGAT (TGAT1 / TGAT2 / TGAT3)' },
        { time: '13.00 - 16.00 น.', subject: 'TPAT3' },
      ],
    },
    {
      index: 3,
      dayIndex: 3,

      date: 'จันทร์ ที่ 11 ธ.ค. 66',
      examSlots: [{ time: '09.00 - 12.00 น.', subject: 'TPAT4' }],
    },
  ],

  ALEVELINFOS: [
    {
      index: 1,
      dayIndex: 4,

      date: 'เสาร์ ที่ 16 มี.ค. 67',
      examSlots: [
        { time: '08.30 - 10.00 น.', subject: 'Bio ชีววิทยา' },
        { time: '11.00 - 12.30 น.', subject: 'Phy ฟิสิกส์' },
        { time: '13.30 - 15.00 น.', subject: 'Thai ไทย' },
        { time: '15.30 - 17.00 น.', subject: 'Soc สังคมศึกษา' },
      ],
    },

    {
      index: 2,
      dayIndex: 5,

      date: 'อาทิตย์ ที่ 17 มี.ค. 67',
      examSlots: [
        { time: '08.30 - 10.00 น.', subject: 'Math1 คณิตศาสตร์ 1' },
        { time: '11.00 - 12.30 น.', subject: 'Eng อังกฤษ' },
        { time: '13.30 - 15.00 น.', subject: 'Chem เคมี' },
      ],
    },
    {
      index: 3,
      dayIndex: 6,
      date: 'จันทร์ ที่ 18 มี.ค. 67',
      examSlots: [
        { time: '08.30 - 10.00 น.', subject: 'Math2 คณิตศาสตร์ 2' },
        { time: '11.00 - 12.30 น.', subject: 'Sci วิทยาศาสตร์' },
        { time: '13.30 - 15.00 น.', subject: 'Fre ฝรั่งเศส' },
        { time: '13.30 - 15.00 น.', subject: 'Ger เยอรมัน' },
        { time: '13.30 - 15.00 น.', subject: 'Jap ญี่ปุ่น' },
        { time: '13.30 - 15.00 น.', subject: 'Kor เกาหลี' },
        { time: '13.30 - 15.00 น.', subject: 'Chi จีน' },
        { time: '13.30 - 15.00 น.', subject: 'Bal บาลี' },
        { time: '13.30 - 15.00 น.', subject: 'Spn สเปน' },
      ],
    },
  ],
};
