import React from 'react';
import { useState, useEffect } from 'react';
import moment from 'moment';

const getEndMoment = (examRound) => {
  switch (examRound) {
    case 'TGATTPAT':
      return moment([2022, 12, 1, 0, 0, 0]);
    case 'ALEVEL':
      return moment('1-12-2024 10:00', 'DD-MM-YYYY HH:mm');
    default:
      return null;
  }
};

const Counter = ({ examRound }) => {
  const [timer, setTimer] = useState(getEndMoment(examRound).diff(moment(), 'seconds'));
  console.log(getEndMoment(examRound).format('LLLL'));
  useEffect(() => {
    const _interval = setInterval(() => setTimer((_time) => _time - (timer ? 1 : 0)), 1000);
    return () => clearInterval(_interval);
  }, [timer]);
  const [hh, mm, ss] = [parseInt(timer / 3600, 10), parseInt(timer / 60, 10) % 60, timer % 60];

  return (
    <ul className="r-counter">
      <li>
        <b>{hh}</b>
        ชั่วโมง
      </li>
      <li>
        <b>{mm}</b>
        นาที
      </li>
      <li>
        <b>{ss}</b>
        วินาที
      </li>
    </ul>
  );
};

export default Counter;
