import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

// Components
import CONSTANTS from 'constants/constants';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  message,
  Input,
  Upload,
  Radio,
  Spin,
  Checkbox,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';
// constants
import { TGATTPATINFOS, ALEVELINFOS } from 'constants/exam-days';

// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

const EXAM_TYPES = {
  pbt: 'กระดาษ',
  cbt: 'คอมพิวเตอร์',
};

moment.locale('th');

export default () => {
  const EXAMDAYS = TGATTPATINFOS.concat(ALEVELINFOS);
  const { day, siteId } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const adminConfig = useSelector((state) => state.configs || {});

  const [currentRound, setCurrentRound] = useState(adminConfig.current_round);
  const [site, setSite] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);

  const [mode, setMode] = useState('display');
  const [isAddActive, setIsAddActive] = useState(false);

  const [tempSelectedRooms, setTempSelectedRooms] = useState([]);

  const [editedRoom, setEditedRoom] = useState(null);
  const [center, setCenter] = useState(null);

  let canEditTestDays;
  switch (adminConfig.center_active_test) {
    case 'TGATTPAT':
      canEditTestDays = ['1', '2', '3'];
      break;
    case 'ALEVEL':
      canEditTestDays = ['4', '5', '6'];
      break;
    default:
      canEditTestDays = [];
      break;
  }

  const canEdit = canEditTestDays.indexOf(day) > -1;

  const onCheckAll = () => {
    if (rooms.length === tempSelectedRooms.length) {
      setTempSelectedRooms([]);
    } else {
      setTempSelectedRooms(rooms.map((room) => room.room_order));
    }
  };

  const onCheckRoom = (room_order) => {
    if (tempSelectedRooms.indexOf(room_order) === -1) {
      setTempSelectedRooms([...tempSelectedRooms, room_order]);
      return;
    }
    let newSelectedRoom = _.filter(tempSelectedRooms, (_room_order) => _room_order !== room_order);
    setTempSelectedRooms(newSelectedRoom);
  };

  const onEditedRoomChange = (field, value) => {
    setEditedRoom({ ...editedRoom, [field]: value });
  };

  const getCenter = async () => {
    const center = await callApi({ url: '/exam-centers/me' });
    setCenter(center);
  };

  const getSchool = async () => {
    const _site = await callApi({ url: `/exam-sites/${siteId}` });
    setSite(_site);
  };
  const getRooms = async () => {
    const _rooms = await callApi({ url: `/exam-sites/${siteId}/rooms` });
    setRooms(_rooms.data);
    return _rooms.data;
  };
  const getSelectedRooms = async () => {
    const _rooms = await callApi({ url: `/exam-sites/${siteId}/days/${day}/rooms` });
    setSelectedRooms(_rooms.data);
    return _rooms.data;
  };

  useEffect(() => {
    getCenter();
    getSchool();
    getSelectedRooms();
    getRooms();
  }, []);

  if (!site) {
    return <div />;
  }

  const onClickEditButton = () => {
    setMode('edit');
    const _tempSelectedRooms = selectedRooms.map((room) => room.room_order);
    setTempSelectedRooms(_tempSelectedRooms);
  };
  const onCancelEdit = () => {
    setMode('display');
    const _tempSelectedRooms = selectedRooms.map((room) => room.room_order);
    setTempSelectedRooms(_tempSelectedRooms);
  };

  const onSaveEditRoom = async () => {
    setLoading(true);
    try {
      await callApi({
        url: `/exam-sites/${siteId}/rooms/days/${day}/${editedRoom._id}`,
        method: 'put',
        body: editedRoom,
      });
    } catch (err) {
      Modal.error({
        title: 'เกิดข้อผิดพลาด',
        content: _.get(err, 'reason.message'),
      });
    }

    await getSelectedRooms();
    setEditedRoom(null);
    setLoading(false);
  };

  const onClickUpdateRoomButton = async () => {
    setLoading(true);

    try {
      // const ids = rooms.map((room) => room._id);
      await callApi({
        url: `/exam-sites/${siteId}/days/${day}/rooms`,
        method: 'post',
        body: { rooms: tempSelectedRooms },
      });
      await getCenter();
      await getSelectedRooms();
      setLoading(false);
      setMode('display');
    } catch (err) {
      message.error(get(err, 'reason[0].message'));
      setLoading(false);
    }
  };

  const dayInfo = find(EXAMDAYS, { dayIndex: parseInt(day, 10) });
  const dayStat = get(center, `info_by_days.day${day}`);

  const renderTable = () => {
    return (
      <>
        <thead className="th-yellow">
          <tr>
            <th>ลำดับ</th>
            <th>ชื่อห้องสอบ</th>
            <th>ชื่ออาคาร</th>
            <th>ชั้น</th>
            <th>แนวกว้าง</th>
            <th>แนวยาว</th>
            <th>จำนวนที่นั่งสอบ</th>
            <th>ประเภทห้อง</th>
            <th>ชนิดห้องสอบ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectedRooms.map((room, i) => {
            if (editedRoom && i === editedRoom.index) {
              return (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td>{editedRoom.room_name}</td>
                  <td>{editedRoom.build_name}</td>
                  <td>
                    <input
                      value={editedRoom.floor}
                      type="number"
                      className="form-control"
                      style={{ width: 60 }}
                      onChange={(e) => onEditedRoomChange('floor', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      value={editedRoom.width}
                      type="number"
                      className="form-control"
                      style={{ width: 60 }}
                      onChange={(e) => onEditedRoomChange('width', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      value={editedRoom.depth}
                      type="number"
                      className="form-control"
                      style={{ width: 60 }}
                      onChange={(e) => onEditedRoomChange('depth', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      value={editedRoom.seat}
                      type="number"
                      className="form-control"
                      style={{ width: 60 }}
                      onChange={(e) => onEditedRoomChange('seat', e.target.value)}
                    />
                  </td>
                  <td>
                    <Select
                      size={'large'}
                      value={editedRoom.room_type}
                      style={{ width: '100px' }}
                      onChange={(value) => onEditedRoomChange('room_type', value)}
                      disabled={editedRoom === 'cbt'}
                    >
                      <Select.Option value={'ปกติ'} key={'ปกติ'}>
                        ปกติ
                      </Select.Option>
                      <Select.Option value={'พิเศษ'} key={'พิเศษ'}>
                        พิเศษ
                      </Select.Option>
                    </Select>
                  </td>
                  <td>
                    <Select
                      size={'large'}
                      value={editedRoom.exam_type || 'pbt'}
                      style={{ width: '140px' }}
                      onChange={(value) => onEditedRoomChange('exam_type', value)}
                      disabled={true}
                    >
                      <Select.Option value={'pbt'} key={'pbt'}>
                        กระดาษ
                      </Select.Option>
                      <Select.Option value={'cbt'} key={'cbt'}>
                        คอมพิวเตอร์
                      </Select.Option>
                    </Select>
                  </td>
                  <td>
                    <a className="color-green text-underline" onClick={() => onSaveEditRoom()}>
                      บันทึก
                    </a>
                    <br />
                    <a className="color-gray" onClick={() => setEditedRoom(null)}>
                      ยกเลิก
                    </a>
                  </td>
                </tr>
              );
            }
            return (
              <tr key={i}>
                <td className="text-center">{i + 1}</td>
                <td>{room.room_name}</td>
                <td>{room.build_name}</td>
                <td>{room.floor}</td>
                <td>{room.width}</td>
                <td>{room.depth}</td>
                <td>{room.seat}</td>
                <td>{room.room_type}</td>
                <td>{EXAM_TYPES[room.exam_type || 'pbt']}</td>
                <td>
                  {!editedRoom && canEdit && (
                    <a
                      onClick={() => {
                        setEditedRoom({ ...room, index: i });
                      }}
                    >
                      แก้ไข
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };

  const renderEditTable = () => {
    let runningNumber = 0;
    return (
      <>
        <thead class="th-yellow">
          <tr>
            <th>
              <Checkbox checked={tempSelectedRooms.length === rooms.length} onChange={() => onCheckAll()}></Checkbox>
            </th>

            <th>ลำดับ</th>
            <th>ชื่อห้องสอบ</th>
            <th>ชื่ออาคาร</th>
            <th>ชั้น</th>
            <th>แนวกว้าง</th>
            <th>แนวยาว</th>
            <th>จำนวนที่นั่งสอบ</th>
            <th>ประเภทห้อง</th>
            <th>ชนิดห้องสอบ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rooms.map((room, i) => {
            const isChecked = tempSelectedRooms.indexOf(room.room_order) > -1;
            if (isChecked) {
              runningNumber++;
            }
            return (
              <tr key={i}>
                <td>
                  <Checkbox checked={isChecked} onChange={() => onCheckRoom(room.room_order)}></Checkbox>
                </td>
                <td className="text-center">{isChecked ? runningNumber : ''}</td>
                <td>{room.room_name}</td>
                <td>{room.build_name}</td>
                <td>{room.floor}</td>
                <td>{room.width}</td>
                <td>{room.depth}</td>
                <td>{room.seat}</td>
                <td>{room.room_type}</td>
                <td>{EXAM_TYPES[room.exam_type || 'pbt']}</td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };

  const countSelectedRooms = _.countBy(selectedRooms, 'room_type');

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>จัดการสนามสอบ</h1>
          <div className="round">
            <div className="round-date">
              <span className="number">{dayInfo.index}</span> <span className="date">{dayInfo.date}</span>
            </div>
            <div className="round-time">
              {dayInfo.examSlots.map((slot) => {
                return (
                  <div className="item">
                    {slot.time} <span className="color-blue">{slot.subject}</span>
                  </div>
                );
              })}
            </div>
            <div className="round-body">
              <div className="col text-center">
                <span>จำนวนสนามสอบ</span> <br />
                <b>{get(dayStat, `sites`)}</b>
              </div>
              <div className="col text-center">
                <span>จำนวนห้องสอบ</span> <br />
                <b>{get(dayStat, `rooms`) + get(dayStat, `special_rooms`) + get(dayStat, `cbt_rooms`) || 0}</b>
              </div>
              <div className="col text-center">
                <span>จำนวนที่นั่ง</span> <br />
                <b>{get(dayStat, `seats`) + get(dayStat, `special_seats`) + get(dayStat, `cbt_seats`) || 0}</b>
              </div>
            </div>
          </div>
          <div className="_space"></div>
          <h3 className="exam-room-title">
            <i className="i-school"></i>
            {site.school_name}
          </h3>
          <div className="flex justify-between">
            <h2>
              <i className="i-school-orange"></i> ห้องสอบ {selectedRooms.length} (ปกติ {countSelectedRooms['ปกติ']} /
              พิเศษ {countSelectedRooms['พิเศษ']}) จาก {rooms.length} ห้อง
            </h2>
          </div>
          <div className="t-collapse">
            <div className="t-card border-orange">
              <div className="filter">
                <h3 className="title">เพิ่มข้อมูลสนามสอบจากฐานข้อมูล</h3>
                <div className="flex">
                  <div className="col-25">
                    <div className="form-group">
                      <label for="">ชื่อสนามสอบ</label>
                      <input type="text" className="form-control" placeholder="ชื่อสนามสอบ" />
                    </div>
                  </div>
                  <div className="col-25">
                    <div className="form-group">
                      <label for="">จังหวัด</label>
                      <input type="text" className="form-control" placeholder="ชื่อสนามสอบ" />
                    </div>
                  </div>
                  <div className="col-25">
                    <div className="form-group">
                      <label for="">อำเภอ</label>
                      <input type="text" className="form-control" placeholder="ชื่อสนามสอบ" />
                    </div>
                  </div>
                  <div className="col-auto flex gap-16 items-center">
                    <a href="#" className="btn-main">
                      ค้นหาสนามสอบ
                    </a>
                    <a href="#" className="_h">
                      ล้างค่า
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="t-table t-table-striped">
            {mode === 'display' && renderTable()}
            {mode === 'edit' && renderEditTable()}
          </table>
          <div className="_space"></div>
          {mode === 'display' && canEdit && (
            <a className="btn-ghost" onClick={() => onClickEditButton()}>
              เพิ่ม-ลด ห้องสอบ
            </a>
          )}
          {mode === 'edit' && (
            <>
              <a className="btn-ghost" onClick={() => onClickUpdateRoomButton()}>
                บันทึกข้อมูล
              </a>
              <a className="_h" onClick={() => onCancelEdit(null)}>
                ยกเลิก
              </a>
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};
